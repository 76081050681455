import { IImage } from '../../models';
import { GProductIcon } from '../icons';

export const GProductHeroGrid = ({ images }: { images?: IImage[] }) => {
  const imgs = images ?? [];

  if (imgs.length <= 0)
    return (
      <div className="grid grid-flow-col grid-rows-2 gap-2 mt-2 text-white">
        <div className="flex items-center col-span-2 row-span-2 overflow-hidden bg-gray-100 dark:bg-gray-800">
          <GProductIcon />
        </div>
        <div className="flex items-center col-span-2 overflow-hidden bg-gray-100 dark:bg-gray-800">
          <GProductIcon width="507px" height="287px" />
        </div>
        <div className="flex items-center col-span-2 overflow-hidden bg-gray-100 dark:bg-gray-800">
          <GProductIcon width="507px" height="287px" />
        </div>
        <div className="flex items-center overflow-hidden bg-gray-100 dark:bg-gray-800">
          <GProductIcon width="507px" height="287px" />
        </div>
        <div className="flex items-center col-span-2 overflow-hidden bg-gray-100 shrink-0 dark:bg-gray-800">
          <GProductIcon width="507px" height="287px" />
        </div>
      </div>
    );

  return (
    <div className="grid grid-flow-col grid-rows-1 gap-2 mt-2 text-white md:grid-rows-2">
      <div className="col-span-2 row-span-2 flex shrink-0 items-center overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-800 md:h-[582px] md:w-[507.4021px]">
        {imgs[0] ? <img key={imgs[0].id} src={imgs[0].src} alt={imgs[0].alt} /> : <GProductIcon />}
      </div>
      <div className="col-span-2 flex shrink-0 items-center overflow-hidden rounded-lg bg-gray-100  dark:bg-gray-800 md:h-[287px] md:w-[507px]">
        {imgs[1] ? <img key={imgs[1].id} src={imgs[1].src} alt={imgs[1].alt} /> : <GProductIcon width="507px" height="287px" />}
      </div>
      <div className="col-span-2 flex shrink-0 items-center overflow-hidden rounded-lg bg-gray-100  dark:bg-gray-800 md:h-[287px] md:w-[507px]">
        {imgs[2] ? <img key={imgs[2].id} src={imgs[2].src} alt={imgs[2].alt} /> : <GProductIcon width="507px" height="287px" />}
      </div>
      <div className="col-span-2 flex shrink-0 items-center  overflow-hidden rounded-lg bg-gray-100  dark:bg-gray-800 md:h-[287px] md:w-[507px]">
        {imgs[3] ? <img key={imgs[3].id} src={imgs[3].src} alt={imgs[3].alt} /> : <GProductIcon width="250px" height="287px" />}
      </div>
      <div className="col-span-2 flex shrink-0 items-center overflow-hidden rounded-lg bg-gray-100  dark:bg-gray-800 md:h-[287px] md:w-[507px]">
        {imgs[4] ? <img key={imgs[4].id} src={imgs[4].src} alt={imgs[4].alt} /> : <GProductIcon width="507px" height="287px" />}
      </div>
    </div>
  );
};

export default GProductHeroGrid;
