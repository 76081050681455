import { Outlet } from "react-router-dom";
import GFooter from "./components/common/footer";
import GHeader from "./components/common/header";
import { useScrollToTop } from "./hooks/use-scroll-to-top";

const App = () => {
  useScrollToTop();

  return (
    <>
      <GHeader />
      <main className="fit">
        <Outlet />
      </main>
      <GFooter />
    </>
  );
};

export default App;
