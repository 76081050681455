import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GModal from '../components/ui/g-modal';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IMember, ITeam } from '../models';
import { supabase } from '../supabase-client';

const Team = () => {
  useTitle('Teams');
  let query = useGQuery();
  const [open, setOpen] = useState(false);
  const [person, setPerson] = useState<IMember>();

  const l = query.get('l') ?? 'en';

  const { data, error } = useQuery(['teams', l], async () => {
    const { data } = await supabase.from<ITeam>('teams').select('*').eq('lang', l).single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="team" />
      <GContainer>
        <h2 className="py-4 text-3xl font-extrabold tracking-tight text-center sm:text-4xl">{data.title}</h2>
        <p className="px-2 text-lg text-center text-gray-500 dark:text-gray-400 md:text-xl">{data.description}</p>

        <ul className="pt-8 mx-auto space-y-16 cursor-pointer sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3">
          {data.members.map((person) => (
            <li
              key={person.name}
              onClick={() => {
                setPerson(person);
                setOpen(true);
              }}
            >
              <div className="space-y-6">
                <img className="object-cover w-40 h-40 mx-auto rounded xl:h-56 xl:w-56" src={person.image} alt="" />
                <div className="space-y-2">
                  <div className="flex flex-col items-center justify-center space-y-1 text-lg font-medium leading-6">
                    <h3>{person.name}</h3>
                    <p className="text-orange dark:text-gray-400">{person.position}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </GContainer>
      <GModal open={open} onClose={() => setOpen(false)}>
        <div
          key={person?.name}
          className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-5xl sm:p-6 sm:align-middle"
        >
          <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
            <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              <img className="object-cover rounded-lg shadow-lg" src={person?.image} alt="" />
            </div>
            <div className="sm:col-span-2">
              <div className="space-y-4">
                <div className="space-y-1 text-lg font-medium leading-6">
                  <h3 className="">{person?.name}</h3>
                  <p className="text-orange dark:text-indigo-400">{person?.position}</p>
                </div>
                <div className="text-lg">
                  <p className="text-gray-500 dark:text-gray-400">{person?.bio}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GModal>
    </>
  );
};

export default Team;
