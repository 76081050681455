import { FC, useEffect, useRef, useState } from "react";
import { InfArrow } from "../../components/icons/inf_pointer";
import {
  ResponsiveIcon,
  ResponsiveText,
} from "../../constants/responsive-class";

interface CollapsibleProp {
  header?: string;
  content?: string;
  defaultVisible?: boolean;
}

const ComplaintCollapsible: FC<CollapsibleProp> = ({
  header,
  content,
  defaultVisible = true,
}) => {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    setVisible(defaultVisible);
  }, [defaultVisible]);

  return (
    <div className="">
      <button
        className="flex flex-row items-center mt-5 cursor-pointer"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <div
          style={{
            rotate: visible ? "0deg" : "-90deg",
            transition: "rotate 0.7s ease-in-out",
          }}
          className={`${ResponsiveIcon.iconSize} `}
        >
          <InfArrow height={"100%"} width={"100%"} />
        </div>
        <h2
          className={`ml-2 font-semibold dark:text-white ${
            visible ? "text-orange" : "text-gray-500"
          }  ${ResponsiveText.subheaderText}`}
        >
          {header}
        </h2>
      </button>

      <p
        ref={contentRef}
        style={{
          maxHeight: visible ? `${contentRef.current?.scrollHeight}px` : "0px",
          opacity: visible ? 1 : 0,
          overflow: "hidden",
          transition: "max-height 0.7s ease-in-out ,opacity 0.7s ease-in-out",
        }}
        className={`${ResponsiveText.contentText} mt-3  text-gray-500 dark:text-gray-400 sm:mt-4  text-left  `}
      >
        {content}
      </p>
    </div>
  );
};

export default ComplaintCollapsible;
