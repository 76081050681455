import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import cn from "clsx";
import { FC, ReactNode, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useGQuery } from "../../hooks/use-g-query";
import { IChildren, IMenu } from "../../models";
import { supabase } from "../../supabase-client";
import GLanguageSwitcher from "../ui/g-language-switcher";
import GThemeToggle from "../ui/g-theme-toggle";
import GHeaders from "./headers";
import GMobileMenu from "./mobile-menu";

const GHeader: FC = () => {
  let query = useGQuery();
  const [open, setOpen] = useState(false);

  const l = query.get("l") ?? "en";
  const { isLoading, error, data } = useQuery<IMenu[] | null>(
    ["get_menus", l],
    async () => {
      const { data } = await supabase.rpc<IMenu>("get_menus", {
        l: l,
      });

      return data;
    }
  );
  if (isLoading) return <h1>Loading...</h1>;
  if (error) return <h1>An error has occurred</h1>;

  return (
    <GHeaders className="relative text-gray-900 transition-all duration-150 bg-white dark:bg-gray-900 dark:text-gray-100 dark:md:bg-gray-800">
      <div className="flex justify-between w-full px-2 mx-auto lg:container lg:px-0">
        <div className="block">
          <Link
            to={l === "km" ? "/?l=km" : "/"}
            className="absolute w-40 top-1 h-44"
          >
            <span className="sr-only">Infinity</span>
            <img
              className="h-[126px] w-[110px] xl:h-[170px] xl:w-[160px]"
              src="https://ncaevhwkfwxiofizozdx.supabase.co/storage/v1/object/public/logos/logo.png"
              alt="infinity"
            />
          </Link>
        </div>

        <div>
          <ul className="hidden xl:flex">
            {data?.map((m) => {
              return (
                <li
                  key={m.id}
                  className={cn(
                    m.children && m.children[0] !== null && "hoverable"
                  )}
                >
                  <NavLink
                    to={m.slug}
                    className={({ isActive }) =>
                      isActive
                        ? "font-bold relative block px-2 py-6 text-sm text-blue-500 uppercase hover:text-primary dark:text-gray-100 dark:hover:text-indigo-500 lg:p-4 lg:text-base xl:py-6 xl:px-4"
                        : "relative block px-2 py-6 text-sm font-semibold text-gray-700 uppercase hover:text-primary dark:text-gray-100 dark:hover:text-indigo-500 lg:p-4 lg:text-base xl:py-6 xl:px-4"
                    }
                  >
                    {m.title}
                  </NavLink>
                  {m?.children && m?.children[0] !== null && (
                    <GGrid key={m.id}>
                      {m?.children?.map((item) => GGridItem(item))}
                    </GGrid>
                  )}
                </li>
              );
            })}
            <li className="flex items-center justify-center">
              <GThemeToggle />
            </li>
            <li className="flex items-center justify-center">
              <GLanguageSwitcher />
            </li>
          </ul>
          <ul className="flex py-3 xl:hidden">
            <li className="flex items-center justify-center mr-2">
              <GThemeToggle />
            </li>
            <li className="flex items-center justify-center mr-2">
              <GLanguageSwitcher />
            </li>
            <li className="flex items-center justify-center">
              <button
                onClick={() => setOpen(!open)}
                className="text-3xl cursor-pointer right-8 top-6"
              >
                {open ? (
                  <XMarkIcon
                    className="w-6 h-6 text-gray-700 dark:text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <Bars3Icon
                    className="w-6 h-6 text-gray-700 dark:text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </button>
            </li>
          </ul>
          <GMobileMenu
            open={open}
            onClose={() => setOpen(!open)}
            menus={data}
          />
        </div>
      </div>
    </GHeaders>
  );
};

export default GHeader;

const GGrid = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="z-10 max-w-4xl mr-5 -ml-56 transform mega-menu w-fit lg:max-w-4xl">
      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="relative grid gap-6 px-5 py-6 bg-white dark:bg-gray-800 sm:gap-8 sm:p-8 lg:grid-cols-2">
          {children}
        </div>
      </div>
    </div>
  );
};

const GGridItem = (item: IChildren) => {
  return (
    <Link
      key={item.id}
      to={item?.slug}
      className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
    >
      <>
        {/* active="bg-gray-100 dark:bg-gray-700" */}
        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-indigo-100 rounded-md dark:bg-gray-700 dark:text-gray-200 sm:h-12 sm:w-12">
          <img src={item.icon} alt="menu" className="h-9 w-9" />
        </div>
        <div
          className={cn(
            "ml-4",
            item?.short_description
              ? ""
              : "flex h-12 items-center justify-center"
          )}
        >
          <p className="text-base font-medium text-gray-900 dark:text-gray-200">
            {item.title}
          </p>
          {item?.short_description && (
            <p className="mt-1 text-xs text-gray-500 truncate dark:text-gray-400">
              {item.short_description}
            </p>
          )}
        </div>
      </>
    </Link>
  );
};
