import { useQuery } from '@tanstack/react-query';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IFaq } from '../models';
import GFaqs from '../sections/faq/faq';
import { supabase } from '../supabase-client';

const Fag = () => {
  useTitle('FAQ');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';
  const { data, error } = useQuery(['faqs', l], async () => {
    const { data } = await supabase
      .from<IFaq>('faqs')
      .select('*,faq_details(id,question,answer,orders)')
      .eq('lang', l)
      // @ts-ignore
      .order('orders', { ascending: true, foreignTable: 'faq_details' })
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="faq" />

      <GContainer>
        <div className="px-2">
          <div className="flex items-start">
            <h1 className="pt-4 pb-4 text-xl font-bold text-center text-gray-900 dark:text-gray-100 sm:text-4xl">{data.title1}</h1>
          </div>
          <p className="pb-4 text-gray-400">{data.subtitle}</p>
          <GFaqs faqs={data.faq_details} />
        </div>
      </GContainer>
    </>
  );
};

export default Fag;
