import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GSpinner from "../components/common/g-spinner";
import GContainer from "../components/ui/g-container";
import { useGQuery } from "../hooks/use-g-query";
import { useTitle } from "../hooks/use-title";
import { EndPoints } from "../libs/utils/constraints";
import { IContact } from "../models";
import GContactAdress from "../sections/contacts/contact-address";
import { supabase } from "../supabase-client";

const GSSwal = withReactContent(Swal);
const alertContent = () => {
  GSSwal.fire({
    title: "Congratulations!",
    text: "Your message was successfully send and will back to you soon",
    icon: "success",
    timer: 5000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

interface IFormValues {
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

const Contact = () => {
  useTitle("Contact");
  let query = useGQuery();
  const l = query.get("l") ?? "en";
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IFormValues>();

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    try {
      reset({ fullName: "", email: "", phone: "", message: "" });

      const { fullName, email, phone, message } = data;
      // https://www.infinity.com.kh/api/email-uat.php
      await fetch(EndPoints.email, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fullname: fullName, email, phone, message }),
      });
      alertContent();
    } catch (error) {}
  };

  const { data, error } = useQuery(["contacts", l], async () => {
    const { data } = await supabase
      .from<IContact>("contacts")
      .select("*")
      .eq("lang", l)
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="conatact" className="" />
      <GContainer>
        <div className="relative mx-auto lg:grid lg:grid-cols-5">
          <div className="px-4 py-16 bg-gray-50 dark:bg-gray-800 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-3xl">
                {data.title}
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500 dark:text-gray-400">
                {data.description}
              </p>

              <GContactAdress contacts={data.address} />
            </div>
          </div>
          <div className="px-4 py-16 bg-white dark:bg-gray-800 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Full Name
                  </label>
                  <input
                    type="text"
                    {...register("fullName", {
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                      minLength: {
                        value: 2,
                        message: "This field must be at least 2 characters",
                      },
                      maxLength: {
                        value: 30,
                        message: "this field must be under 30 characters",
                      },
                    })}
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-700"
                    placeholder="Full Name*"
                  />
                  {errors.fullName && <div>{errors.fullName.message}</div>}
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    autoComplete="email"
                    {...register("email")}
                    // {...register('email', {
                    //   required: { value: true, message: 'This field is required.' },
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //     message: 'This field must be an valid email address',
                    //   },
                    // })}
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-700"
                    placeholder="Email"
                  />
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    autoComplete="tel"
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                      minLength: {
                        value: 2,
                        message: "This field must be at least 2 characters",
                      },
                      maxLength: {
                        value: 30,
                        message: "this field must be under 30 characters",
                      },
                    })}
                    aria-describedby="phone-optional"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-700"
                    placeholder="Phone Number*"
                  />
                  {errors.phone && <div>{errors.phone.message}</div>}
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    {...register("message")}
                    rows={4}
                    className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-700"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-8 py-3 text-base font-medium text-white border border-transparent rounded-sm shadow-sm bg-orange hover:bg-orange/75 dark:bg-gray-700 dark:hover:bg-gray-600"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </GContainer>
    </>
  );
};

export default Contact;
