export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SEND_GRID_API_KEY = process.env.REACT_APP_SEND_GRID_API_KEY;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GA_ID = process.env.REACT_APP_GA_ID;

export const EndPoints = {
  email: BASE_URL + "email",
  complaint: BASE_URL + "complaint",
};

// export const CONTACT_API = process.env.NODE_ENV === 'production' ? 'https://www.infinity.com.kh/api/email.php' : '/api/contact';
// export const CONTACT_API = process.env.NODE_ENV === 'production' ? 'https://119.82.249.98/api_website_uat/email' : 'https://119.82.249.98/api_website_uat/email';
// export const CONTACT_API = process.env.NODE_ENV === 'production' ? 'https://119.82.249.98/api_website/email' : 'http://localhost:53442/email';

// 119.82.249.98

// https://119.82.249.98/api_website/email
