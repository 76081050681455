import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { FC, ReactElement } from "react";
import Divider from "../../components/common/divider";
import { InfArrow } from "../../components/icons/inf_pointer";
import { Color } from "../../constants/colors";
import { IComplaint } from "../../models/complaint";
import { ResponsiveText } from "../../constants/responsive-class";

interface FormDownloadProp {
  data: IComplaint;
}

interface ToggleTextProp {
  children: string | ReactElement;
  khmer: string | null;
  khmer_url: string | null;
  english: string | null;
  english_url: string | null;
}

const ComplaintFormDownload: FC<FormDownloadProp> = ({ data }) => {
  return (
    <>
      <div className="bg-gray-100 dark:bg-gray-800 w-full p-5 rounded-[10px]   hidden lg:block">
        <div className="flex flex-row items-center bg-orange h-max pl-2 pr-3 rounded-[100px] w-max">
          <div style={{ rotate: "270deg" }}>
            <InfArrow width={25} height={25} />
          </div>
          <h2
            className={`ml-[5px] text-white font-medium uppercase ${ResponsiveText.footerLabelText}`}
          >
            {data.title}
          </h2>
        </div>
        <div className="flex-row items-start justify-between flex mt-3">
          <div className="justify-start">
            <div className="flex flex-row items-center mt-3">
              <EnvelopeIcon height={24} width={24} stroke={Color.orange} />
              <p
                className={`${ResponsiveText.contactInfoText} ml-[5px] text-gray-500 dark:text-white font-medium`}
              >
                {data.email}
              </p>
            </div>
            <div className="flex flex-row items-center mt-3">
              <PhoneIcon height={24} width={24} stroke={Color.orange} />
              <p
                className={`${ResponsiveText.contactInfoText} ml-[5px] text-gray-500 dark:text-white font-medium`}
              >
                {data.phone}
              </p>
            </div>
            <div className="flex flex-row items-center mt-3">
              <PhoneIcon height={24} width={24} stroke={Color.orange} />
              <p
                className={`${ResponsiveText.contactInfoText} ml-[5px] text-gray-500 dark:text-white font-medium `}
              >
                {data.phone2}
              </p>
            </div>
          </div>
          <div>
            <h2 className="ml-[5px] text-gray-500 dark:text-white font-medium text-lg uppercase">
              {data.download_form_text}
            </h2>
            <Divider />
            <div className="flex flex-row h-max mx-[10px]">
              <div className="mx-[5px] pt-3">
                <h2 className="text-gray-500 dark:text-gray-400 font-medium text-base lg:text-sm uppercase">
                  {data.category1}
                </h2>
                <div className="flex flex-row items-center mt-3">
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form1_url}
                    english_url={data.form1_url}
                  >
                    {data.form_type1 ?? ""}
                  </ToggleText>
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form2_url}
                    english_url={data.form2_url}
                  >
                    {data.form_type2 ?? ""}
                  </ToggleText>
                </div>
              </div>
              <Divider className="bg-gray-200 dark:bg-gray-700 flex flex-1 w-[1px]" />
              <div className="mx-[5px] pt-3">
                <h2 className=" text-gray-500 dark:text-gray-400 font-medium text-base lg:text-sm uppercase">
                  {data.category2}
                </h2>
                <div className="flex flex-row items-center mt-3">
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form3_url}
                    english_url={data.form3_url}
                  >
                    {data.form_type1 ?? ""}
                  </ToggleText>
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form4_url}
                    english_url={data.form4_url}
                  >
                    {data.form_type2 ?? ""}
                  </ToggleText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden grid grid-cols-12 gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 w-full pb-3 rounded-[10px] col-span-12  overflow-hidden">
          <div className="flex flex-row items-center bg-orange h-max w-full justify-center py-2">
            <div style={{ rotate: "270deg" }}>
              <InfArrow width={25} height={25} />
            </div>
            <h2
              className={`ml-[5px] text-white font-medium uppercase ${ResponsiveText.footerLabelText}`}
            >
              {data.title}
            </h2>
          </div>
          <div className="pl-3">
            <div className="flex flex-row items-center mt-3">
              <EnvelopeIcon height={24} width={24} stroke={Color.orange} />
              <p className="ml-[5px] text-gray-500 dark:text-white font-medium sm:text-base text-[13px]">
                {data.email}
              </p>
            </div>
            <div className="flex flex-row items-center mt-3">
              <PhoneIcon height={24} width={24} stroke={Color.orange} />
              <p className="ml-[5px] text-gray-500 dark:text-white font-medium sm:text-base text-[13px] ">
                {data.phone}
              </p>
            </div>
            <div className="flex flex-row items-center mt-3">
              <PhoneIcon height={24} width={24} stroke={Color.orange} />
              <p className="ml-[5px] text-gray-500 dark:text-white font-medium sm:text-base text-[13px]">
                {data.phone2}
              </p>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="ml-[5px] text-gray-500 dark:text-white font-medium text-[14px] sm:text-lg uppercase">
              {data.download_form_text}
            </h2>
            <Divider />
            <div className="flex flex-row h-max mx-[10px] justify-evenly">
              <div className="pt-3">
                <h2 className="text-gray-500 dark:text-gray-400 font-medium text-[12px] lg:text-sm uppercase">
                  {data.category1}
                </h2>
                <div className="flex flex-row items-center mt-3">
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form1_url}
                    english_url={data.form1_url}
                  >
                    {data.form_type1 ?? ""}
                  </ToggleText>
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form2_url}
                    english_url={data.form2_url}
                  >
                    {data.form_type2 ?? ""}
                  </ToggleText>
                </div>
              </div>
              <Divider className="bg-gray-200 dark:bg-gray-700  w-[1px] hidden sm:block" />
              <div className="pt-3">
                <h2 className=" text-gray-500 dark:text-gray-400 font-medium text-[12px] lg:text-sm uppercase">
                  {data.category2}
                </h2>
                <div className="flex flex-row items-center mt-3">
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form3_url}
                    english_url={data.form3_url}
                  >
                    {data.form_type1 ?? ""}
                  </ToggleText>
                  <ToggleText
                    english={data.english}
                    khmer={data.khmer}
                    khmer_url={data.kh_form4_url}
                    english_url={data.form4_url}
                  >
                    {data.form_type2 ?? ""}
                  </ToggleText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintFormDownload;

const ToggleText = ({
  children,
  english,
  khmer,
  english_url,
  khmer_url,
}: ToggleTextProp) => {
  return (
    <div className="relative inline-block group">
      <button
        className={`${ResponsiveText.formCategoryText} mx-[10px] md:mx-[20px] lg:mx-[10px] xl:mx-[30px] text-purple-900 dark:text-gray-200 dark:underline  font-semibold  dark:hover:text-orange dark:focus:text-orange hover:text-orange focus:text-orange uppercase`}
      >
        {children}
      </button>
      <div className="opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 mt-1 bg-gray-300 dark:bg-gray-900 py-3">
        <ul>
          <li className="my-[5px]">
            <a
              href={khmer_url ?? "/"}
              className="text-[12px] text-purple-900 dark:text-gray-200 dark:underline font-semibold hover:text-orange dark:hover:text-orange"
            >
              {khmer}
            </a>
          </li>
          <li className="my-[5px]">
            <a
              href={english_url ?? "/"}
              className="text-[12px] text-purple-900 dark:text-gray-200 dark:underline font-semibold hover:text-orange dark:hover:text-orange"
            >
              {english}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
