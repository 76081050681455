import { useQuery } from "@tanstack/react-query";
import GSpinner from "../components/common/g-spinner";
import GContainer from "../components/ui/g-container";
import { useGQuery } from "../hooks/use-g-query";
import { IComplaint } from "../models/complaint";
import ComplaintCollapsible from "../sections/complaint/complaint-collapsible";
import { supabase } from "../supabase-client";
import Divider from "../components/common/divider";
import ComplaintFormDownload from "../sections/complaint/complaint-form-download";
import ComplaintSubmitForm from "../sections/complaint/complaint-submit-form";
import {
  ResponsivePadding,
  ResponsiveText,
} from "../constants/responsive-class";

const Complaint = () => {
  const query = useGQuery();
  const language_code = query.get("l") ?? "en";
  const { data, error } = useQuery(["complaint", language_code], async () => {
    const { data } = await supabase
      .from<IComplaint>("complaint")
      .select("*")
      .eq("lang", language_code)
      .single();
    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data?.image1} alt="claims" className="mt-5" />
      <GContainer>
        <div className="py-4 md:py-8 ">
          <div
            className={`${ResponsivePadding.contentPadding} max-w-fit mx-auto text-center mb-[10px]`}
          >
            <h2
              className={`${ResponsiveText.headerText} font-extrabold text-transparent uppercase bg-gradient-to-r from-primary to-orange bg-clip-text`}
            >
              {data?.title}
            </h2>
            <p
              className={`${ResponsiveText.contentText} mt-3 text-gray-500 dark:text-gray-400 sm:mt-4 text-center`}
            >
              {data?.description}
            </p>
            <ComplaintCollapsible
              defaultVisible={true}
              header={data?.title1}
              content={data?.description1}
            />
            <ComplaintCollapsible
              defaultVisible={true}
              header={data?.title2}
              content={data?.description2}
            />
          </div>
        </div>
        <img
          src={data?.image2}
          alt="Complain Handling Process"
          className="w-full h-auto"
        />
        <div
          className={`${ResponsivePadding.contentPadding} max-w-full mx-auto text-center mb-[10px]`}
        >
          <div className="mb-3">
            <Divider />
          </div>
          <div className="px-[15px] mb-3">
            <ComplaintFormDownload data={data} />
          </div>
          <ComplaintSubmitForm data={data} />
        </div>
      </GContainer>
    </>
  );
};

export default Complaint;
