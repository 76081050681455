import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GGridProducts from '../components/ui/g-grid-products';
import { GTextGradient } from '../components/ui/g-text-gradient';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IProduct } from '../models';
import { supabase } from '../supabase-client';

const Corportate = () => {
  useTitle('Corporate');
  let query = useGQuery();
  let location = useLocation();

  const l = query.get('l') ?? 'en';
  const slug = location.pathname + (l === 'km' ? '?l=km' : '');

  const { data, error } = useQuery(['corporate_view_menus', slug, l], async () => {
    const { data } = await supabase.from<IProduct>('view_menus').select('*').eq('slug', slug).eq('lang', l).single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data?.image} alt="corporate" />
      <GContainer>
        <div className="text-center">
          <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl md:text-4xl">
            <GTextGradient text={data.title} />
          </h1>
          <p className="max-w-md mx-2 mt-3 text-base text-gray-500 sm:text-lg md:mx-auto md:mt-5 md:max-w-5xl md:text-xl">{data.description}</p>
        </div>
        <div className="pt-4 mx-2">
          <GGridProducts products={data?.children ?? []} />
        </div>
      </GContainer>
    </>
  );
};

export default Corportate;
