import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GList from '../components/ui/g-list';
import GMailto from '../components/ui/g-mail-to';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { ICareerDetail } from '../models';
import { supabase } from '../supabase-client';

export default function CareersDetails() {
  useTitle('Career Details');
  let query = useGQuery();
  let params = useParams();
  const l = query.get('l') ?? 'en';

  const slug = params.slug + (l === 'km' ? '?l=km' : '');
  const { data, error } = useQuery(['careerdetails', l, slug], async () => {
    const { data } = await supabase
      .from<ICareerDetail>('career_details')
      .select('id,position,department,type,close_date,location,orders,slug,lang,description,responsibilities,requirements,note,email,metadata')
      .eq('lang', l)
      .eq('slug', slug)
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <GContainer>
        <div key={data.position} className="flex flex-col my-2 overflow-hidden shadow-lg">
          <div className="flex flex-col justify-between flex-1 p-6 bg-white dark:bg-gray-800">
            <div className="flex-1">
              <div className="flex items-center justify-center mt-8">
                <span className="text-xl font-semibold text-gray-900 dark:text-gray-200">{data?.position}</span>
              </div>
              <div className="flex items-center justify-center my-2 space-x-1">
                <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-1 text-xs font-medium text-primary dark:bg-gray-700 dark:text-gray-400">
                  <time dateTime={format(parseISO(data.close_date), 'EEEE, MMMM dd, yyyy')}>Close Date: {format(parseISO(data.close_date), 'EEEE, MMMM dd, yyyy')}</time>
                </span>
                <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-1 text-xs font-medium uppercase text-primary dark:bg-gray-700 dark:text-gray-400">
                  {data.type}
                </span>
                <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-1 text-xs font-medium uppercase text-primary dark:bg-gray-700 dark:text-gray-400">
                  {data.department}
                </span>
                <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-1 text-xs font-medium uppercase text-primary dark:bg-gray-700 dark:text-gray-400">
                  {data.location}
                </span>
              </div>
              <div className="py-3">
                {data?.description?.length ?? -1 > 0
                  ? data.description?.map((d, i) => (
                      <span key={`desc${i}`} className="block my-2 text-base text-gray-500 dark:text-gray-300">
                        {d}
                      </span>
                    ))
                  : null}
              </div>
              {data?.metadata?.reportTo ? (
                <div className="block my-2">
                  <span className="text-base text-gray-700 dark:text-gray-200">{data?.metadata?.reportTo?.label ?? 'NA: '}</span>
                  <span className="text-base text-gray-700 dark:text-gray-200">{data?.metadata?.reportTo?.value ?? 'NA'}</span>
                </div>
              ) : null}

              <span className="block mt-2">
                <p className="text-base text-gray-700 dark:text-gray-200">{data?.responsibilities?.title}</p>
                {data?.responsibilities && <GList items={data?.responsibilities?.data as string[]} icon />}
                <p className="text-base text-gray-700 dark:text-gray-200">{data?.requirements?.title}</p>
                {data?.requirements && <GList items={data?.requirements?.data as string[]} icon />}
              </span>
              <span className="block mt-2">
                <p className="text-base text-gray-500 dark:text-gray-400">
                  {data?.note}
                  <GMailto className="text-blue-700 dark:text-blue-400" email={data?.email ?? ''}>
                    {data.email}
                  </GMailto>
                </p>
              </span>
            </div>
          </div>
        </div>
      </GContainer>
    </>
  );
}
