import { Dialog, Disclosure, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import cn from "clsx";
import { FC, Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IMenu } from "../../models";

export interface GMobileMenuProps {
  open?: boolean;
  onClose: () => void;
  menus?: IMenu[] | null;
  children?: ReactNode;
}

export const GMobileMenu: FC<GMobileMenuProps> = ({
  children,
  open,
  onClose,
  menus,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[999] overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md pointer-events-auto">
                <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-blue-500">
                        <Link to="/">INFINITY</Link>
                      </Dialog.Title>
                      <div className="flex items-center ml-3 h-7">
                        <button
                          type="button"
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 mt-3 sm:px-6">
                    <dl className="space-y-3 divide-y divide-gray-200 dark:divide-gray-700 ">
                      {(menus || []).map((m) => (
                        <Disclosure as="div" key={m.id} className="pt-2">
                          {({ open }) => (
                            <>
                              <dt className="text-lg">
                                <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                                  <span className="font-normal text-gray-700 dark:text-gray-500">
                                    {m.title}
                                  </span>
                                  <span className="flex items-center ml-6 h-7">
                                    <ChevronDownIcon
                                      className={cn(
                                        open ? "-rotate-180" : "rotate-0",
                                        "h-6 w-6 transform"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Disclosure.Button>
                              </dt>

                              <Disclosure.Panel
                                as="dd"
                                className="flex flex-col pr-12 mt-2 space-y-2"
                              >
                                {m.children &&
                                  m.children[0] !== null &&
                                  m.children.map((m1) => (
                                    <Link
                                      key={m1.id}
                                      to={m1.slug}
                                      className="flex"
                                    >
                                      <ChevronRightIcon
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      <span className="text-sm text-gray-500 dark:text-gray-400">
                                        {m1.title}
                                      </span>
                                    </Link>
                                  ))}
                                <Link key={m.id} to={m.slug} className="flex">
                                  <ChevronRightIcon
                                    className="flex-shrink-0 w-5 h-5 text-green-400 dark:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm text-gray-400 dark:text-gray-400">
                                    ALL
                                  </span>
                                </Link>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GMobileMenu;
