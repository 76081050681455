import { Link } from 'react-router-dom';
import { IProduct } from '../../models';

export const GGridProducts = ({ products }: { products: IProduct[] }) => {
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {products?.map((c) => (
        <li
          key={c.id}
          className="flex flex-col col-span-1 text-center divide-y divide-gray-200 rounded-lg shadow bg-gray-50 shadow-primary/10 hover:shadow-xl hover:shadow-primary/20 dark:bg-gray-800"
        >
          <Link to={c.slug} className="flex flex-col flex-1 p-8">
            <img className="flex-shrink-0 w-32 h-32 mx-auto rounded" src={c.icon} alt="" />
            <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-white ">{c.title}</h3>
            <dl className="flex flex-col justify-between flex-grow mt-1">
              <dt className="sr-only">{c.title}</dt>
              {c.short_description && (
                <dd className="text-sm text-gray-500">
                  <span className="px-2 py-1 text-xs font-medium text-gray-500 uppercase bg-[#fff7ed] rounded dark:bg-gray-700 dark:text-gray-500">{c.short_description}</span>
                </dd>
              )}
            </dl>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default GGridProducts;
