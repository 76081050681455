import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import Gallery from 'react-photo-gallery';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GModalImage from '../components/ui/g-modal-image';
import { useTitle } from '../hooks/use-title';
import { IPhoto } from '../models';
import { supabase } from '../supabase-client';

const PhotoGallery = () => {
  useTitle('Photo Gallery');
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const { data, error } = useQuery(['photos'], async () => {
    const { data } = await supabase.from<IPhoto>('photos').select('*,photo_details(id,title,src,width,height)').eq('lang', 'en').order('created_at', { ascending: false }).single();

    return data;
  });

  // @ts-ignore
  const openLightbox = useCallback((_event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handelRight = () => {
    const totalLength = data.photo_details.length;
    if (currentImage + 1 >= totalLength) {
      setCurrentImage(0);
      return;
    }

    setCurrentImage(currentImage + 1);
  };

  const handelLeft = () => {
    const totalLength = data.photo_details.length;
    if (currentImage === 0) {
      setCurrentImage(totalLength - 1);
      return;
    }

    setCurrentImage(currentImage - 1);
  };

  return (
    <>
      <img src={data.image1} alt="gallery" />
      <GContainer>
        <div>
          <Gallery photos={data.photo_details} onClick={openLightbox} />
          {viewerIsOpen ? (
            <GModalImage open={viewerIsOpen} onClose={closeLightbox} onLeft={handelLeft} onRight={handelRight} src={data.photo_details[currentImage].src}></GModalImage>
          ) : null}
        </div>
      </GContainer>
    </>
  );
};

export default PhotoGallery;
