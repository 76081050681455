import { useEffect } from 'react';

const RedirectUrl = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Redirecting...</h5>;
};

export default RedirectUrl;
