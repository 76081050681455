import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';

export interface ModalProps {
  src: string;
  open?: boolean;
  onClose: () => void;
  onLeft: () => void;
  onRight: () => void;
  children?: ReactNode;
}

export default function GModalImage({ src, open, onClose, onLeft, onRight, children }: ModalProps) {
  // const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-[99] overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full h-screen p-2 overflow-hidden text-left transition-all transform bg-white shadow-xl">
                <div className="relative w-full h-full px-6 py-6 bg-gray-800">
                  <div className="absolute inset-0 overflow-hidden">
                    <img src={src} alt="" className="object-cover object-center w-full h-full" />
                  </div>
                  <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                    <button type="button" className="text-gray-100 bg-gray-900 rounded-md hover:text-gray-500" onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="absolute top-[50%] right-0 pt-4 pr-4 sm:block">
                    <button type="button" className="text-gray-100 bg-gray-900 rounded-md hover:text-gray-500" onClick={onRight}>
                      <span className="sr-only">Close</span>
                      <ChevronRightIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="absolute top-[50%] left-0 pt-4 pl-4 sm:block">
                    <button type="button" className="text-gray-100 bg-gray-900 rounded-md hover:text-gray-500" onClick={onLeft}>
                      <span className="sr-only">Close</span>
                      <ChevronLeftIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
