import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import GSpinner from "../components/common/g-spinner";
import GClientMarquee from "../components/ui/g-client-marquee";
import GContainer from "../components/ui/g-container";
import { useGQuery } from "../hooks/use-g-query";
import { useTitle } from "../hooks/use-title";
import { IVision } from "../models";
import { supabase } from "../supabase-client";

const Vision = () => {
  useTitle("Vision");
  let query = useGQuery();
  const l = query.get("l") ?? "en";
  const { data, error } = useQuery(["visions", l], async () => {
    const { data } = await supabase
      .from<IVision>("visions")
      .select("*")
      .eq("lang", l)
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="vision1" />
      <GContainer>
        <h3 className="px-2 py-4 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-3xl">
          {data.title}
        </h3>
        <div className="px-2 space-y-2 text-lg text-gray-500 dark:text-gray-400">
          {data.description.map((d, i) => (
            <p key={`Vision${i}`}>{d}</p>
          ))}
        </div>
        <dl className="px-2 mt-4 space-y-10">
          {data.vmc.map((item) => (
            <div key={item.label} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center w-12 h-12 text-white rounded-md bg-orange dark:bg-gray-700 dark:text-gray-300">
                  <CheckCircleIcon className="w-6 h-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                  {item.label}
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-lg text-gray-500 dark:text-gray-400">
                <p>{item.description}</p>
                <ul className="mt-6 space-y-4">
                  {item.list?.map((l, i) => (
                    <li key={`vmc${i}`} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-lg text-gray-500 dark:text-gray-400">{`${l.label}: ${l.description}`}</span>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
        </dl>
        <GClientMarquee />
      </GContainer>
    </>
  );
};

export default Vision;
