import { MapPinIcon } from '@heroicons/react/24/outline';
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useMemo, useRef, useState } from 'react';
import { IClinicPanel } from '../../models';

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;

export const GMap = ({ panels }: { panels: IClinicPanel[] }) => {
  const [currentLocation, setCurrentLocation] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
  // const [bounds, setBounds] = useState<google.maps.LatLngBounds | undefined>(undefined);

  const mapRef = useRef<GoogleMap>();
  const [panel, setPanel] = useState<LatLngLiteral>();
  const [directions] = useState<DirectionsResult>();

  const [showPosition, setShowPosition] = useState<LatLngLiteral>();
  const [showInfo, setShowInfo] = useState<{ clinic?: string; description?: string; map_link?: string }>({ clinic: 'NA', description: 'NA' });

  const options = useMemo<MapOptions>(
    () => ({
      // mapId: '785c1f5147d39dd0',
      disableDefaultUI: false,
      clickableIcons: true,
    }),
    []
  );

  const onLoad = (map: google.maps.Map) => {
    // navigator?.geolocation.getCurrentPosition;
    navigator?.geolocation.watchPosition(({ coords: { latitude: lat, longitude: lng } }) => {
      setCurrentLocation({ lat, lng });
    });
    // google.maps.event.addListener(map, 'bounds_changed', () => {
    //   setBounds(map.getBounds());
    // });
  };

  const blueDot = {
    fillColor: 'blue',
    fillOpacity: 1,
    path: google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: 'white',
    strokeWeight: 2,
  };
  return (
    <div className="flex h-screen">
      <div className="hidden w-0 p-0 text-white bg-gray-800 md:block md:w-1/5 md:p-4">
        <h1 className="text-lg font-semibold">Panels</h1>
        <ul className="mt-6 h-[calc(100%-64px)] space-y-4 overflow-auto">
          {panels.map((c) => (
            <li key={c.lat} className="flex space-x-3">
              <MapPinIcon className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-400" aria-hidden="true" />
              <button
                className="text-sm text-gray-500 dark:text-gray-400"
                onClick={() => {
                  setPanel({ lat: c.lat, lng: c.lng });
                  setShowInfo({ clinic: c.title, description: c.description, map_link: c.map_link });
                  setShowPosition({ lat: c.lat, lng: c.lng });
                  mapRef.current?.panTo({ lat: c.lat, lng: c.lng });
                }}
                key={c.lat}
              >
                {c.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full h-screen md:w-4/5">
        <GoogleMap mapContainerClassName="w-full h-screen" zoom={13} center={panel || currentLocation} options={options} onLoad={onLoad}>
          {directions && <DirectionsRenderer directions={directions} />}

          {currentLocation && (
            <>
              <Marker position={currentLocation} title="ME" icon={blueDot} />
            </>
          )}
          {panels &&
            panels.map((c) => (
              <Marker
                key={c.lat}
                position={{ lat: c.lat, lng: c.lng }}
                title={c.title}
                onClick={() => {
                  setShowInfo({ clinic: c.title, description: c.description, map_link: c.map_link });
                  setShowPosition({ lat: c.lat, lng: c.lng });
                }}
              />
            ))}
          {showPosition && (
            <InfoWindow onCloseClick={() => setShowPosition(undefined)} position={showPosition}>
              <div>
                <h1 className="font-semibold text-gray-800">{showInfo.clinic}</h1>
                <p className="text-gray-600">{showInfo?.description}</p>
                {showInfo?.map_link && (
                  <a target="_blank" className="text-blue-700" href={showInfo.map_link} rel="noreferrer">
                    go to google map
                  </a>
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </div>
  );
};
export default GMap;
