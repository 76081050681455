import { CheckIcon } from '@heroicons/react/24/outline';

export const GList = ({ items, icon = false }: { icon?: boolean; items: string[] }) => {
  return (
    <ul className="my-3 space-y-2">
      {items.map((l, i) => (
        <li key={`$glist${i}`} className="flex space-x-3">
          {icon && <CheckIcon className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-400" aria-hidden="true" />}
          <span className="text-gray-500 dark:text-gray-400">{l}</span>
        </li>
      ))}
    </ul>
  );
};

export default GList;
