import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGQuery } from '../../hooks/use-g-query';
import ClickOutside from '../../libs/click-outside';

interface LOCALE_DATA {
  name: string;
  img: {
    filename: string;
    alt: string;
  };
}

const LOCALES_MAP: Record<string, LOCALE_DATA> = {
  km: {
    name: 'Khmer',
    img: {
      filename: '/flags/kh.svg',
      alt: 'Khmer Flage',
    },
  },
  en: {
    name: 'English',
    img: {
      filename: '/flags/en.svg',
      alt: 'US Flag',
    },
  },
};

const locales = ['en', 'km'];

export const GLanguageSwitcher = () => {
  const [display, setDisplay] = useState(false);
  let query = useGQuery();
  let location = useLocation();

  const l = query.get('l') ?? 'en';
  const options = locales?.filter((val) => val !== l);
  const currentLocale = l;

  return (
    <ClickOutside active={display} onClick={() => setDisplay(false)}>
      <nav className="relative">
        <div className="relative flex items-center" onClick={() => setDisplay(!display)}>
          <button className="flex items-center justify-center h-10 px-2 transition-colors ease-linear" aria-label="Language selector">
            <img className="block w-8 h-6 mr-2" src={LOCALES_MAP[currentLocale].img.filename} alt={LOCALES_MAP[currentLocale].img.alt} />
          </button>
        </div>
        <div className="absolute top-0 right-0">
          {options?.length && display ? (
            <div className="fixed right-0 z-40 w-full h-full mt-2 origin-top-right bg-white outline-none top-12 dark:bg-gray-800 lg:absolute lg:h-auto lg:w-56 lg:border lg:shadow-lg">
              <div className="flex flex-row justify-end">
                <button onClick={() => setDisplay(false)} aria-label="Close panel" className="p-6 bg-gray-700 md:hidden">
                  <XMarkIcon className="w-6 h-6" />
                </button>
              </div>
              <ul>
                {options.map((locale) => (
                  <li key={locale}>
                    <Link
                      to={`${location.pathname}?l=${locale}`}
                      className="flex items-center px-6 py-3 font-medium leading-6 text-gray-700 capitalize transition duration-150 ease-in-out cursor-pointer hover:text-primary dark:text-gray-300 dark:hover:text-white"
                      onClick={() => setDisplay(false)}
                    >
                      {LOCALES_MAP[locale].name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
    </ClickOutside>
  );
};

export default GLanguageSwitcher;
