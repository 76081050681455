import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "next-themes";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import NotFound from "./routes/404";
import Careers from "./routes/careers";
import CareersDetails from "./routes/careers-details";
import Claims from "./routes/claims";
import Clinics from "./routes/clinics";
import Complaint from "./routes/complaint";
import Contact from "./routes/contact";
import Corportate from "./routes/corporate";
import CorporateDetails from "./routes/corporate-details";
import CSR from "./routes/csr";
import Fag from "./routes/faq";
import Garages from "./routes/garages";
import Home from "./routes/home";
import NewsLetters from "./routes/newsletters";
import NewsLettersDetails from "./routes/newsletters-details";
import Personal from "./routes/personal";
import PersonalDetails from "./routes/personal-details";
import PhotoGallery from "./routes/photo";
import RedirectUrl from "./routes/redirect-url";
import Team from "./routes/team";
import Videos from "./routes/videos";
import Vision from "./routes/vision";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/index.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider attribute="class">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route path="corporate">
                <Route index element={<Corportate />} />
                <Route path=":slug" element={<CorporateDetails />} />
              </Route>
              <Route path="personal">
                <Route index element={<Personal />}></Route>
                <Route path=":slug" element={<PersonalDetails />} />
              </Route>
              <Route path="csr" element={<CSR />} />
              <Route path="faq" element={<Fag />} />
              <Route path="claims" element={<Claims />} />
              <Route path="contact" element={<Contact />} />
              <Route path="clinics" element={<Clinics />} />
              <Route path="garages" element={<Garages />} />
              <Route path="video" element={<Videos />} />
              <Route path="newsletters">
                <Route index element={<NewsLetters />} />
                <Route path=":slug" element={<NewsLettersDetails />} />
              </Route>

              <Route path="photo" element={<PhotoGallery />} />
              <Route path="vision" element={<Vision />} />
              <Route path="team" element={<Team />} />
              <Route path="careers">
                <Route index element={<Careers />} />
                <Route path=":slug" element={<CareersDetails />} />
              </Route>
              <Route
                path="hcip"
                element={<RedirectUrl url="https://119.82.249.98/hcip/" />}
              />
              <Route
                path="hcip_uat"
                element={<RedirectUrl url="https://119.82.249.98/hcip_uat/" />}
              />
              <Route
                path="quickquote"
                element={
                  <RedirectUrl url="https://119.82.249.98/quickquote/" />
                }
              />
              <Route
                path="quickquote_uat"
                element={
                  <RedirectUrl url="https://119.82.249.98/quickquote_uat/" />
                }
              />
              <Route
                path="agency"
                element={<RedirectUrl url="https://119.82.249.98/agency/" />}
              />
              <Route path="complaint" element={<Complaint />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

reportWebVitals();
