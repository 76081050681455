import { CheckIcon } from "@heroicons/react/24/outline";
import { IWhyDescription } from "../../models";

export const GWhyInfinity = ({
  title,
  image,
  description,
}: {
  title: string[];
  image: string;
  description: IWhyDescription;
}) => {
  return (
    <div className="relative bg-gray-50 dark:bg-gray-900">
      <div className="lg:relative">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-normal text-gray-900 dark:text-gray-100 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">{title[0]}</span>{" "}
              <span className="block text-orange xl:inline">{title[1]}</span>
            </h1>
            <dd className="mt-2 text-lg text-gray-500 dark:text-gray-400">
              <p>{description?.content ?? ""}</p>
              {description?.list && (
                <ul className="mt-6 space-y-4">
                  {description.list?.map((l, i) => (
                    <li key={`vmc${i}`} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-lg text-gray-500 dark:text-gray-400">
                        {l}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </dd>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={image}
            alt="reward"
          />
        </div>
      </div>
    </div>
  );
};

export default GWhyInfinity;
