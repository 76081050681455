import { useQuery } from '@tanstack/react-query';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IClaim } from '../models';
import GContact from '../sections/claims/contact';
import { supabase } from '../supabase-client';

const Claims = () => {
  useTitle('Claims');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';
  const { data, error } = useQuery(['claims', l], async () => {
    const { data } = await supabase.from<IClaim>('claims').select('*').eq('lang', l).single();
    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="claims" className="" />
      <GContainer>
        <div className="py-4 md:py-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 uppercase dark:text-white sm:text-4xl">{data.title}</h2>
            <p className="mt-3 text-lg text-gray-900 dark:text-gray-400 sm:mt-4 md:text-xl">{data.description}</p>
          </div>
        </div>

        <div role="list" className="grid grid-cols-1 gap-6 px-2 sm:grid-cols-2 lg:grid-cols-2">
          <GContact contact={data.medical} />
          <GContact contact={data.property} />
          <GContact contact={data.motor_vehicle} />
          <GContact contact={data.travel_other} />
        </div>
      </GContainer>
    </>
  );
};

export default Claims;
