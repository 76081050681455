import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { IAddress } from '../../models';

export const GContactAdress = ({ contacts }: { contacts: IAddress[] }) => {
  return (
    <>
      {contacts.map((a) => (
        <dl key={a.label} className="mt-8 text-base text-gray-500 dark:text-gray-400">
          <h2 className="text-xl font-extrabold tracking-tight text-gray-900 dark:text-white">{a.label}</h2>
          <div>
            <dt className="sr-only">Postal address</dt>
            <dd>
              <p>{a.address}</p>
            </dd>
          </div>
          <div className="mt-6">
            <dt className="sr-only">Phone number</dt>
            <dd className="flex">
              <PhoneIcon className="flex-shrink-0 w-6 h-6 text-gray-400" aria-hidden="true" />
              <span className="ml-3">{a.phone}</span>
            </dd>
          </div>
          <div className="mt-3">
            <dt className="sr-only">Email</dt>
            <dd className="flex">
              <EnvelopeIcon className="flex-shrink-0 w-6 h-6 text-gray-400" aria-hidden="true" />
              <span className="ml-3">{a.email ?? 'NA'}</span>
            </dd>
          </div>
        </dl>
      ))}
    </>
  );
};

export default GContactAdress;
